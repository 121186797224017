import { defineLocale } from "../composables/defineLocale.js";
export default defineLocale({
  name: "\u010Ce\u0161tina",
  code: "cs",
  messages: {
    inputMenu: {
      noMatch: "\u017D\xE1dn\xE1 shoda",
      noData: "\u017D\xE1dn\xE1 data",
      create: 'Vytvo\u0159it "{label}"'
    },
    inputNumber: {
      increment: "Zv\xFD\u0161it",
      decrement: "Sn\xED\u017Eit"
    },
    commandPalette: {
      noMatch: "\u017D\xE1dn\xE1 shoda",
      noData: "\u017D\xE1dn\xE1 data",
      close: "Zav\u0159\xEDt"
    },
    selectMenu: {
      noMatch: "\u017D\xE1dn\xE1 shoda",
      noData: "\u017D\xE1dn\xE1 data",
      create: 'Vytvo\u0159it "{label}"'
    },
    toast: {
      close: "Zav\u0159\xEDt"
    },
    carousel: {
      prev: "P\u0159edchoz\xED",
      next: "Dal\u0161\xED",
      goto: "P\u0159ej\xEDt na {slide}"
    },
    modal: {
      close: "Zav\u0159\xEDt"
    },
    slideover: {
      close: "Zav\u0159\xEDt"
    },
    alert: {
      close: "Zav\u0159\xEDt"
    },
    table: {
      noData: "\u017D\xE1dn\xE1 data"
    }
  }
});
