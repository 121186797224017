import { defineLocale } from "../composables/defineLocale.js";
export default defineLocale({
  name: "\u0420\u0443\u0441\u0441\u043A\u0438\u0439",
  code: "ru",
  messages: {
    inputMenu: {
      noMatch: "\u0421\u043E\u0432\u043F\u0430\u0434\u0435\u043D\u0438\u0439 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E",
      noData: "\u041D\u0435\u0442 \u0434\u0430\u043D\u043D\u044B\u0445",
      create: '\u0421\u043E\u0437\u0434\u0430\u0442\u044C "{label}"'
    },
    inputNumber: {
      increment: "\u0423\u0432\u0435\u043B\u0438\u0447\u0438\u0442\u044C",
      decrement: "\u0423\u043C\u0435\u043D\u044C\u0448\u0438\u0442\u044C"
    },
    commandPalette: {
      noMatch: "\u0421\u043E\u0432\u043F\u0430\u0434\u0435\u043D\u0438\u0439 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E",
      noData: "\u041D\u0435\u0442 \u0434\u0430\u043D\u043D\u044B\u0445",
      close: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C"
    },
    selectMenu: {
      noMatch: "\u0421\u043E\u0432\u043F\u0430\u0434\u0435\u043D\u0438\u0439 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E",
      noData: "\u041D\u0435\u0442 \u0434\u0430\u043D\u043D\u044B\u0445",
      create: '\u0421\u043E\u0437\u0434\u0430\u0442\u044C "{label}"'
    },
    toast: {
      close: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C"
    },
    carousel: {
      prev: "\u041D\u0430\u0437\u0430\u0434",
      next: "\u0414\u0430\u043B\u0435\u0435",
      goto: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043A {slide}"
    },
    modal: {
      close: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C"
    },
    slideover: {
      close: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C"
    },
    alert: {
      close: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C"
    },
    table: {
      noData: "\u041D\u0435\u0442 \u0434\u0430\u043D\u043D\u044B\u0445"
    }
  }
});
