import { defineLocale } from "../composables/defineLocale.js";
export default defineLocale({
  name: "\u7E41\u4F53\u4E2D\u6587",
  code: "zh-Hant",
  messages: {
    inputMenu: {
      noMatch: "\u6C92\u6709\u5339\u914D\u7684\u8CC7\u6599",
      noData: "\u6C92\u6709\u8CC7\u6599",
      create: '\u5275\u5EFA "{label}"'
    },
    inputNumber: {
      increment: "\u589E\u52A0",
      decrement: "\u51CF\u5C11"
    },
    commandPalette: {
      noMatch: "\u6C92\u6709\u5339\u914D\u7684\u8CC7\u6599",
      noData: "\u6C92\u6709\u8CC7\u6599",
      close: "\u95DC\u9589"
    },
    selectMenu: {
      noMatch: "\u6C92\u6709\u5339\u914D\u7684\u8CC7\u6599",
      noData: "\u6C92\u6709\u8CC7\u6599",
      create: '\u5275\u5EFA "{label}"'
    },
    toast: {
      close: "\u95DC\u9589"
    },
    carousel: {
      prev: "\u4E0A\u4E00\u9801",
      next: "\u4E0B\u4E00\u9801",
      goto: "\u8DF3\u8F49\u5230\u7B2C {slide} \u9801"
    },
    modal: {
      close: "\u95DC\u9589"
    },
    slideover: {
      close: "\u95DC\u9589"
    },
    alert: {
      close: "\u95DC\u9589"
    },
    table: {
      noData: "\u6C92\u6709\u8CC7\u6599"
    }
  }
});
