import { useState } from "#imports";
export function useToast() {
  const toasts = useState("toasts", () => []);
  function add(toast) {
    const body = {
      id: (/* @__PURE__ */ new Date()).getTime().toString(),
      open: true,
      ...toast
    };
    const index = toasts.value.findIndex((t) => t.id === body.id);
    if (index === -1) {
      toasts.value.push(body);
    }
    toasts.value = toasts.value.slice(-5);
    return body;
  }
  function update(id, toast) {
    const index = toasts.value.findIndex((t) => t.id === id);
    if (index !== -1) {
      toasts.value[index] = {
        ...toasts.value[index],
        ...toast
      };
    }
  }
  function remove(id) {
    const index = toasts.value.findIndex((t) => t.id === id);
    if (index !== -1) {
      toasts.value[index] = {
        ...toasts.value[index],
        open: false
      };
    }
    setTimeout(() => {
      toasts.value = toasts.value.filter((t) => t.id !== id);
    }, 200);
  }
  function clear() {
    toasts.value = [];
  }
  return {
    toasts,
    add,
    update,
    remove,
    clear
  };
}
