import { shallowRef } from "vue";
import { defineNuxtPlugin } from "#imports";
import { slideoverInjectionKey } from "../composables/useSlideover.js";
export default defineNuxtPlugin((nuxtApp) => {
  const slideoverState = shallowRef({
    component: "div",
    props: {}
  });
  nuxtApp.vueApp.provide(slideoverInjectionKey, slideoverState);
});
