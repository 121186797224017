import { computed, inject, ref } from "vue";
import { buildLocaleContext } from "../utils/locale.js";
import { en } from "../locale/index.js";
import { createSharedComposable } from "@vueuse/core";
export const localeContextInjectionKey = Symbol("nuxt-ui.locale-context");
const _useLocale = (localeOverrides) => {
  const locale = localeOverrides || inject(localeContextInjectionKey, ref());
  return buildLocaleContext(computed(() => locale.value || en));
};
export const useLocale = createSharedComposable(_useLocale);
