import {
  useSiteConfig
} from "#imports";
import { defineNuxtPlugin } from "nuxt/app";
import { ref } from "vue";
import { applyDefaults } from "../logic/applyDefaults.js";
export default defineNuxtPlugin({
  name: "nuxt-seo:defaults",
  order: 999,
  env: {
    islands: false
  },
  setup() {
    const siteConfig = useSiteConfig();
    const locale = ref(siteConfig.currentLocale || siteConfig.defaultLocale);
    applyDefaults({
      locale
    });
  }
});
