import { defineLocale } from "../composables/defineLocale.js";
export default defineLocale({
  name: "Deutsch",
  code: "de",
  messages: {
    inputMenu: {
      noMatch: "Nichts gefunden",
      noData: "Keine Daten",
      create: '"{label}" erstellen'
    },
    inputNumber: {
      increment: "Erh\xF6hen",
      decrement: "Verringern"
    },
    commandPalette: {
      noMatch: "Nichts gefunden",
      noData: "Keine Daten",
      close: "Schlie\xDFen"
    },
    selectMenu: {
      noMatch: "Nichts gefunden",
      noData: "Keine Daten",
      create: '"{label}" erstellen'
    },
    toast: {
      close: "Schlie\xDFen"
    },
    carousel: {
      prev: "Weiter",
      next: "Zur\xFCck",
      goto: "Gehe zu {slide}"
    },
    modal: {
      close: "Schlie\xDFen"
    },
    slideover: {
      close: "Schlie\xDFen"
    },
    alert: {
      close: "Schlie\xDFen"
    },
    table: {
      noData: "Keine Daten"
    }
  }
});
